<template>
  <tfoms-panel :hide-border="true">
    <v-row v-if="!loading">
      <v-col>
        <div style="width: 200px; margin: auto">
          <doughnut-chart
            :chart-data="doughnutData"
            :options="doughnutOptions"
            :common-percent="(value ? Math.round(value) : 0) + '%'"
            :title="`Оценено`"
          ></doughnut-chart>
        </div>
      </v-col>
      <v-col class="kibmd-info-chart__content">
        <div class="kibmd-info-chart__text" style="height: 100%">
          <div class="kibmd-info-chart__text-date">{{ year }} год</div>
          <div class="kibmd-info-chart__text-desc">
            {{ description }}
          </div>
          <div class="kibmd-info-chart__text-caption" v-if="false">
            Состояние оценено путем проведения плановых и внеплановых проверок
            11 из 12 показателей
          </div>
        </div>
      </v-col>
    </v-row>
  </tfoms-panel>
</template>
<script>
export default {
  components: {
    tfomsPanel: () => import("@/components/analysis/panel.vue"),
    DoughnutChart: () => import("@/views/analysis/DoughnutChart.js"),
  },
  props: {
    value: { type: Number, default: 22 },
    description: {
      type: String,
      default: "",
    },
    year: {
      type: Number,
      default: 2024,
    },
  },
  data() {
    return {
      loading: true,
      doughnutData: {
        datasets: [
          {
            data: [99, 1],
            backgroundColor: ["#BBC6F0", "#FFC805"],
            hoverOffset: 4,
          },
        ],
      },
      doughnutOptions: {
        tooltips: {
          enabled: false,
        },
      },
    };
  },
  watch: {
    value() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      await new Promise((resolve) => {
        setTimeout(() => resolve(), 400);
      });
      this.doughnutData.datasets[0].data = [this.value, 100 - this.value];
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.kibmd-info-chart {
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &-date {
      color: #2757ff !important;
      font-size: 14px;
      line-height: 100%;
      font-weight: 500;
      margin-bottom: 5px;
    }
    &-desc {
      color: var(--div, #5f647b);
      font-size: 16px;
      line-height: 120%;
      font-weight: 500;
      margin-bottom: 20px;
    }
    &-caption {
      color: var(--div, #5f647b);
      font-size: 12px;
      line-height: 100%;
      font-weight: 500;
    }
  }
}
</style>
